<template>
    <p>Are you sure you want to delete this Project?</p>
    <br />
    <p>[ {{ item.name }} ]</p>
</template>

<script setup lang="ts">
import { type IProject } from '../../models/Project';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useProjectRepository } from '@app/land-hold/project/repositories/useProjectRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IProject>,
        required: true,
    },
});

const destroy = useProjectRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
