<template>
    <AdminPanelTemplate>
        <template #breadcrumbs>
            <Breadcrumbs :items="useBreadcrumb([[useRoute().build(Property.routes().index), useRoute().build(Inventory.routes().management.properties.index)]]).breadcrumbs(page.props.breadcrumbs)" />
        </template>
        <template #content>
            <v-card class="ma-3">
                <v-card-title>
                    Property Details
                    <GenericActionDialog confirm-btn-text="Update" title="Edit Property" :disabled="false" @confirm="confirm($event)">
                        <template #activator="{ props: activatorProps }">
                            <v-btn variant="text" v-bind="activatorProps" :loading="processing">Edit</v-btn>
                        </template>
                        <template #body>
                            <UpdatePropertyDialog :item="page.props.data" />
                        </template>
                    </GenericActionDialog>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" sm="12" md="6" xl="4">
                                <v-row>
                                    <v-col cols="12" md="12" class="mb-5">
                                        <ReadOnlyTextField :value="page.props.data.property_name" label="Property Name" />
                                    </v-col>
                                    <v-col cols="12" class="mb-5">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.property_code" label="Property Code" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.property_short_code" label="Short Code" />
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <ReadOnlyTextField :value="page.props.data.owner.data.name" label="Owner" />
                                            </v-col>
                                            <v-col cols="12" md="12">
                                                <ReadOnlyTextField :value="page.props.data.steward.data.steward_code" label="Steward" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" class="mb-5">
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <ReadOnlyTextField :value="page.props.data.state.data._3_letter_code" label="State" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.community" label="Community" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.municipality" label="Municipality" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.property_area_ha" type="number" label="Area (ha)" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.partition_status" label="Partition Status" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.partitioned_area" label="Partitioned Area (ha)" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.unpartition_area" label="Unpartitioned Area (ha)" />
                                            </v-col>
                                        </v-row>
                                        <v-card-actions>
                                            <v-btn :to="usePropertyRepository().query.indexPropertyPartitions(page.props.data.id).endpoint" color="primary">View Partitions </v-btn>
                                        </v-card-actions>
                                    </v-col>
                                    <v-col cols="12" class="mb-5">
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.inventory_type.data.name" label="Inventory Type" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyTextField :value="page.props.data.priority" label="Inventory Queue Priority" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyDateTextField :date="page.props.data.start_date" label="Start Date" />
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <ReadOnlyDateTextField :date="page.props.data.end_date" label="End Date" />
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12">
                                        <Autocomplete v-model:selected-items="page.props.data.projects.data" field-name="name" :items="page.props.data.projects.data" label="Project(s)" active multiple readonly />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </template>
    </AdminPanelTemplate>
</template>

<script setup lang="ts">
import { Property } from '@app/land-hold/property/models/Property';
import { useSubmitForm } from '@shared/composables/data-table/useSubmitForm';
import { useRoute } from '@shared/composables/useRoute';
import { useBreadcrumb } from '@shared/composables/useBreadcrumb';
import { Inventory } from '@app/land-hold/inventory/models/Inventory';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';

const page = Property.routes().show.page;

const { confirm, processing } = useSubmitForm();
</script>
