<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <ValidatableTextField v-model:form="form" label="Name" :field="'name'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.steward_id" v-model:form="form" :field-name="'steward_id'" :items="stewards" label="Steward" :loading="fetchingStewards" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.approval_authority_id" v-model:form="form" :field-name="'approval_authority_id'" :items="approvalAuthorities" label="Approval Authority" :loading="fetchingApprovalAuthorities" />
            </v-col>
            <v-col cols="6">
                <DatePicker v-model:field="form.start_date" v-model:form="form" label="Start Date" :field-name="'start_date'" />
            </v-col>
            <v-col cols="6">
                <DatePicker v-model:field="form.end_date" v-model:form="form" label="End Date" :field-name="'end_date'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.property_ids" field-name="property_ids" item-title="property_code" item-value="id" :items="properties" label="Properties" multiple :loading="fetchingProperties" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { useProjectRepository } from '@app/land-hold/project/repositories/useProjectRepository';
import { useFetchListData } from '@shared/composables/useFetchListData';
import { useRoute } from '@shared/composables/useRoute';
import { type ISteward, Steward } from '@app/land-hold/steward/models/Steward';
import {
    ApprovalAuthority,
    type IApprovalAuthority
} from '@app/land-hold/approval-authority/models/ApprovalAuthority';
import type { IProject } from '@app/land-hold/project/models/Project';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository.ts';

const props = defineProps({
    item: {
        type: Object as PropType<IProject>,
        required: true,
    },
});

const store = useProjectRepository<IProject & { property_ids: string[] }>().command.store({
    ...props.item,
    property_ids: [],
});
const form = store.form;
useInjectSubmitForm(store.execute);

const { data: stewards, processing: fetchingStewards } = useFetchListData<ISteward>(useRoute().build(Steward.routes().index), 'appSection@steward::IndexStewardsPage');
const { data: approvalAuthorities, processing: fetchingApprovalAuthorities } = useFetchListData<IApprovalAuthority>(useRoute().build(ApprovalAuthority.routes().index), 'appSection@approvalAuthority::IndexApprovalAuthoritiesPage');
const { data: properties, processing: fetchingProperties } = usePropertyRepository().query.list();
</script>
