<template>
    <p>Are you sure you want to delete this Inventory?</p>
    <br />
    <p>[ {{ item.inventory_code }} ]</p>
</template>

<script setup lang="ts">
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import type { IInventory } from '@app/land-hold/inventory/models/Inventory';
import { useInventoryRepository } from '@app/land-hold/inventory/repositories/useInventoryRepository';

const props = defineProps({
    item: {
        type: Object as PropType<IInventory>,
        required: true,
    },
});

const destroy = useInventoryRepository().command.destroy(props.item.id);
useInjectSubmitForm(destroy.execute);
</script>
