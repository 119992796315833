<template>
    <v-footer :color="theme.global.current.value.colors.background">
        <v-row>
            <v-col>
                <span style="font-size: 12px; color: dimgrey">Powered by </span>
                <a href="https://openforests.com" style="font-size: 12px; font-weight: bold; color: dimgrey" target="_blank" rel="noopener noreferrer">OpenForests</a>
                <br />
                <span style="font-size: 12px; font-style: italic; color: grey">Empowering sustainable landscapes through digital innovation.</span>
            </v-col>
        </v-row>
    </v-footer>
</template>

<script setup lang="ts">
import { useTheme } from 'vuetify';

const theme = useTheme();
</script>
