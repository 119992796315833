import { Model } from '@shared/models/Model';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import type { DataTableHeaders } from '@shared/types/Vuetify';
import type { IModel } from '@shared/types/Model';
import { useRoute } from '@shared/composables/useRoute';

export interface ICertifier extends IModel {
    name: string;
    embed_url: string | null;
    public: boolean;
}

export class Certifier extends Model implements ICertifier {
    public name!: string;
    public embed_url!: string | null;
    public public!: boolean;

    public constructor(o: ICertifier) {
        super(o);
        Object.assign(this, o);
    }

    public static override headers(): DataTableHeaders<ICertifier> {
        return [
            {
                title: 'Actions',
                key: 'actions',
                sortable: false,
                fixed: true,
                width: 113,
                minWidth: '113',
                headerProps: { style: { borderRight: '1px solid rgba(0, 0, 0, 0.12)' } },
            },
            {
                title: 'Name',
                key: 'name',
                link: (item) => useRoute().build(Certifier.routes().show, { id: item.id }),
            },
            {
                title: 'Embedded Map',
                key: 'embed_url',
                sortable: false,
                value: (item) => embeddedUrl(item),
                renderAsHtml: true,
            },
            { title: 'Public', key: 'public', value: (item) => publicStyle(item), renderAsHtml: true },
            { title: 'Created At', key: 'created_at' },
            { title: 'Updated At', key: 'updated_at' },
        ];
    }

    public static override routes(): {
        index: IndexRoute<'certifiers.index', ICertifier>;
        show: ShowRoute<'certifiers.show'>;
        destroy: DestroyRoute<'certifiers.destroy'>;
        create: CreateRoute<'certifiers.create'>;
        store: StoreRoute<'certifiers.store'>;
        edit: EditRoute<'certifiers.edit'>;
        update: UpdateRoute<'certifiers.update'>;
    } {
        return {
            index: {
                method: 'get',
                name: 'certifiers.index',
                params: {
                    orderBy: 'name',
                    sortedBy: 'desc',
                },
                datatableHeaders: () => Certifier.headers(),
                page: usePage(),
            },
            show: { method: 'get', name: 'certifiers.show', page: usePage() },
            destroy: { method: 'delete', name: 'certifiers.destroy', page: usePage() },
            create: { method: 'get', name: 'certifiers.create', page: usePage() },
            store: { method: 'post', name: 'certifiers.store', page: usePage() },
            edit: { method: 'get', name: 'certifiers.edit', page: usePage() },
            update: { method: 'patch', name: 'certifiers.update', page: usePage() },
        };
    }

    public static getPublicFieldLabel(value: boolean): string {
        return value ? 'Public' : 'Not Public';
    }

    public static getPublicFieldStateClassColor(value: boolean): string {
        return value ? 'success' : 'error';
    }
}

function embeddedUrl(certifier: ICertifier): string {
    return `<a target="_blank" href="${certifier.embed_url}">preview link</a>`;
}

function publicStyle(certifier: ICertifier): string {
    const chipColor = certifier.public ? 'text-success' : 'text-error';

    return `
    <span class="v-chip v-theme--defaultLight ${chipColor} v-chip--density-default v-chip--rounded v-chip--size-small v-chip--variant-tonal" draggable="false">
        <span class="v-chip__underlay">
            </span>
            <div class="v-chip__content" data-no-activator="">${certifier.public ? 'Yes' : 'No'}</div>
        </span>
    </span>`;
}
