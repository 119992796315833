<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Property Name" :field="'property_name'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Community" :field="'community'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" label="Municipality" :field="'municipality'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" type="number" label="Area (ha)" :field="'property_area_ha'" />
            </v-col>
            <v-col cols="12" sm="6">
                <ReadOnlyTextField :value="form.property_short_code" label="Short Code" />
            </v-col>
            <v-col cols="12" sm="6">
                <ReadOnlyTextField :value="form.property_code" label="Property Code" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.owner_id" v-model:form="form" :field-name="'owner_id'" :items="owners" :loading="fetchingOwners" label="Owner" />
            </v-col>
            <v-col cols="6">
                <Autocomplete v-model:selected-items="form.inventory_type_id" v-model:form="form" :field-name="'inventory_type_id'" :items="inventoryTypes" :loading="fetchingInventoryTypes" label="Inventory Type" />
            </v-col>
            <v-col cols="12" sm="6">
                <ValidatableTextField v-model:form="form" type="number" label="Inventory Queue Priority" :field="'priority'" />
            </v-col>
            <v-col cols="6">
                <Autocomplete v-model:selected-items="form.steward_id" v-model:form="form" :field-name="'steward_id'" item-title="steward_code" :items="stewards" :loading="fetchingStewards" label="Steward" />
            </v-col>
            <v-col cols="6">
                <Autocomplete v-model:selected-items="form.state_id" v-model:form="form" :field-name="'state_id'" item-title="_3_letter_code" :items="states" :loading="fetchingStates" label="State" />
            </v-col>
            <v-col cols="6">
                <DatePicker v-model:field="form.start_date" v-model:form="form" label="Start Date" :field-name="'start_date'" />
            </v-col>
            <v-col cols="6">
                <DatePicker v-model:field="form.end_date" v-model:form="form" label="End Date" :field-name="'end_date'" />
            </v-col>
            <v-col cols="12">
                <Autocomplete v-model:selected-items="form.project_ids" field-name="project_ids" item-title="name" item-value="id" :items="projects" label="Projects" multiple :loading="fetchingProjects" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { type IProperty } from '@app/land-hold/property/models/Property';
import { useInjectSubmitForm } from '@shared/composables/action-dialog/useInjectSubmitForm';
import { type IOwner, Owner } from '@app/land-hold/owner/models/Owner';
import { type ISteward, Steward } from '@app/land-hold/steward/models/Steward';
import { type IState, State } from '@app/location/state/models/State';
import { useFetchListData } from '@shared/composables/useFetchListData';
import { useRoute } from '@shared/composables/useRoute';
import { type IInventoryType, InventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import { usePropertyRepository } from '@app/land-hold/property/repositories/usePropertyRepository';
import type { ProjectsInclude } from '@app/land-hold/property/routes/routes.ts';
import { useProjectRepository } from '@app/land-hold/project/repositories/useProjectRepository.ts';
import type { IProject } from '@app/land-hold/project/models/Project.ts';

const props = defineProps({
    item: {
        type: Object as PropType<IProperty & ProjectsInclude>,
        required: true,
    },
});

const update = usePropertyRepository<IProperty & { project_ids: string[] }>().command.update(props.item.id, {
    ...props.item,
    project_ids: [],
});
const form = update.form;
useInjectSubmitForm(update.execute);

let projects = ref([] as IProject[]);
let fetchingProjects = ref(false);
watch(
    () => form.steward_id,
    () => {
        if (form.steward_id) {
            const { data, processing } = useProjectRepository().query.listAllowedFor(form.steward_id);
            // eslint-disable-next-line vue/no-ref-as-operand
            projects = data;
            // eslint-disable-next-line vue/no-ref-as-operand
            fetchingProjects = processing;

            if (form.steward_id === props.item.steward_id) {
                form.project_ids = props.item.projects.data.map((project) => project.id);
            } else {
                form.project_ids = [];
            }
        } else {
            form.project_ids = [];
        }
    },
    {
        immediate: true,
    }
);
const { data: inventoryTypes, processing: fetchingInventoryTypes } = useFetchListData<IInventoryType>(useRoute().build(InventoryType.routes().index), 'appSection@inventoryType::IndexInventoryTypesPage');
const { data: owners, processing: fetchingOwners } = useFetchListData<IOwner>(useRoute().build(Owner.routes().index), 'appSection@owner::IndexOwnersPage');
const { data: stewards, processing: fetchingStewards } = useFetchListData<ISteward>(useRoute().build(Steward.routes().index), 'appSection@steward::IndexStewardsPage');
const { data: states, processing: fetchingStates } = useFetchListData<IState>(useRoute().build(State.routes().index), 'appSection@state::IndexStatesPage');
</script>
