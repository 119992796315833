import type { IOwner } from '@app/land-hold/owner/models/Owner';
import type { ISteward } from '@app/land-hold/steward/models/Steward';
import type { IState } from '@app/location/state/models/State';
import { type IProperty, Property } from '../models/Property';
import type { IInventoryView } from '@app/land-hold/inventory/models/InventoryView';
import { Partition } from '@app/land-hold/partition/models/Partition';
import type { IndexPartitionIncludes} from '@app/land-hold/partition/routes/routes';
import { AvailableIncludes as PartitionAvailableIncludes } from '@app/land-hold/partition/routes/routes';
import type { IInventoryType } from '@app/land-hold/inventory-type/models/InventoryType';
import type { MultiObjectInclude, SingleObjectInclude, PaginatedResponse, SingleObjectResponse, Include } from '@shared/types/ApiResponse';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import type { IProject } from '@app/land-hold/project/models/Project';

export const AvailableIncludes = {
    Owner: 'owner',
    Steward: 'steward',
    State: 'state',
    InventoriesView: 'inventories_view',
    InventoriesByYear: 'inventories_by_year',
    InventoryType: 'inventory_type',
    Projects: 'projects',
} as const;

type InventoryTypeInclude = Include<typeof AvailableIncludes, 'inventory_type', IInventoryType, SingleObjectInclude<IInventoryType>>;
type OwnerInclude = Include<typeof AvailableIncludes, 'owner', IOwner, SingleObjectInclude<IOwner>>;
type StewardInclude = Include<typeof AvailableIncludes, 'steward', ISteward, SingleObjectInclude<ISteward>>;
type StateInclude = Include<typeof AvailableIncludes, 'state', IState, SingleObjectInclude<IState>>;
type InventoriesViewInclude = Include<typeof AvailableIncludes, 'inventories_view', IInventoryView, MultiObjectInclude<IInventoryView>>;
export type ProjectsInclude = Include<typeof AvailableIncludes, 'projects', IProject, MultiObjectInclude<IProject>>;
export type Year = Record<'year', number>;
type InventoriesByYearInclude = Include<typeof AvailableIncludes, 'inventories_by_year', Year, MultiObjectInclude<Year>>;

export type IPropertyDefaultIncludes = InventoriesByYearInclude;
type IPropertyWithDefaultIncludes = IProperty & IPropertyDefaultIncludes;
export type AllPropertyIncludes = IPropertyWithDefaultIncludes & InventoryTypeInclude & OwnerInclude & StewardInclude & StateInclude & InventoriesViewInclude & ProjectsInclude;
export type IndexPropertyIncludes = IPropertyWithDefaultIncludes & InventoryTypeInclude & OwnerInclude & StewardInclude & StateInclude & ProjectsInclude;
export type ShowPropertyIncludes = IndexPropertyIncludes;

export function routes(): {
    index: IndexRoute<'properties.index', IndexPropertyIncludes>;
    show: ShowRoute<'properties.show', ShowPropertyIncludes>;
    destroy: DestroyRoute<'properties.destroy'>;
    create: CreateRoute<'properties.create'>;
    store: StoreRoute<'properties.store'>;
    edit: EditRoute<'properties.edit'>;
    update: UpdateRoute<'properties.update'>;
    partitions: {
        index: IndexRoute<
            'properties.partitions.index',
            IndexPartitionIncludes,
            {
                property: SingleObjectResponse<IProperty>;
            } & PaginatedResponse<IndexPartitionIncludes>
        >;
    };
} {
    return {
        index: { method: 'get', name: 'properties.index', params: { include: [AvailableIncludes.InventoryType, AvailableIncludes.Owner, AvailableIncludes.Steward, AvailableIncludes.State, AvailableIncludes.Projects], orderBy: 'property_name', sortedBy: 'desc' }, datatableHeaders: () => Property.headers(), page: usePage() },
        show: { method: 'get', name: 'properties.show', params: { include: [AvailableIncludes.InventoryType, AvailableIncludes.Owner, AvailableIncludes.Steward, AvailableIncludes.State, AvailableIncludes.Projects] }, page: usePage() },
        destroy: { method: 'delete', name: 'properties.destroy', page: usePage() },
        create: { method: 'get', name: 'properties.create', page: usePage() },
        store: { method: 'post', name: 'properties.store', page: usePage() },
        edit: { method: 'get', name: 'properties.edit', page: usePage() },
        update: { method: 'patch', name: 'properties.update', page: usePage() },
        partitions: {
            index: {
                method: 'get',
                name: 'properties.partitions.index',
                params: { include: [PartitionAvailableIncludes.LanduseType, PartitionAvailableIncludes.Property], orderBy: 'created_at', sortedBy: 'desc' },
                datatableHeaders: () => Partition.propertyPartitionsHeaders(),
                page: usePage(),
            },
        },
    };
}
