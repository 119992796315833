<template>
    <v-row no-gutters class="fill-height" align-content="space-between">
        <v-col cols="12">
            <DataTable
                title="Transactions"
                :headers="PublicTransaction.headers()"
                :model-class="PublicTransaction"
                :items="page.props.data"
                :search-endpoint="() => useRoute().build(PublicTransaction.routes().index)"
                :crud-config="{
                    showCreateAction: false,
                    showUpdateAction: false,
                    showDeleteAction: false,
                    showViewAction: true,
                    showCustomAction: false,
                }"
                :config="useConfig().publicTransaction"
            >
            </DataTable>
        </v-col>
        <v-col cols="12">
            <ThePublicRegistryFooter />
        </v-col>
    </v-row>
</template>

<script setup lang="ts">
import { PublicTransaction } from '@app/purchase/transaction/models/PublicTransaction';
import { useConfig } from '@shared/composables/useConfig';
import { useRoute } from '@shared/composables/useRoute';

const page = PublicTransaction.routes().index.page;
</script>
