import { type IProperty } from '@app/land-hold/property/models/Property';
import type { CreateRoute, DestroyRoute, EditRoute, IndexRoute, ShowRoute, StoreRoute, UpdateRoute } from '@shared/types/Route';
import { type IProject, Project } from '@app/land-hold/project/models/Project';
import type { Include, MultiObjectInclude, SingleObjectInclude } from '@shared/types/ApiResponse';
import type { ISteward } from '@app/land-hold/steward/models/Steward';
import type { IApprovalAuthority } from '@app/land-hold/approval-authority/models/ApprovalAuthority';

const AvailableIncludes = {
    Steward: 'steward',
    ApprovalAuthority: 'approvalAuthority',
    Properties: 'properties',
} as const;

type StewardInclude = Include<typeof AvailableIncludes, 'steward', ISteward, SingleObjectInclude<ISteward>>;
type ApprovalAuthorityInclude = Include<typeof AvailableIncludes, 'approvalAuthority', IApprovalAuthority, SingleObjectInclude<IApprovalAuthority>>;
type PropertiesInclude = Include<typeof AvailableIncludes, 'properties', IProperty, MultiObjectInclude<IProperty>>;

export type AllProjectIncludes = IProject & StewardInclude & ApprovalAuthorityInclude & PropertiesInclude;
export type IndexProjectIncludes = IProject & StewardInclude & ApprovalAuthorityInclude & PropertiesInclude;
export function routes(): {
    index: IndexRoute<'projects.index', IndexProjectIncludes>;
    show: ShowRoute<'projects.show'>;
    destroy: DestroyRoute<'projects.destroy'>;
    create: CreateRoute<'projects.create'>;
    store: StoreRoute<'projects.store'>;
    edit: EditRoute<'projects.edit'>;
    update: UpdateRoute<'projects.update'>;
} {
    return {
        index: {
            method: 'get',
            name: 'projects.index',
            params: { include: [AvailableIncludes.Steward, AvailableIncludes.ApprovalAuthority, AvailableIncludes.Properties], orderBy: 'name', sortedBy: 'desc' },
            datatableHeaders: () => Project.headers(),
            page: usePage(),
        },
        show: { method: 'get', name: 'projects.show', page: usePage() },
        destroy: { method: 'delete', name: 'projects.destroy', page: usePage() },
        create: { method: 'get', name: 'projects.create', page: usePage() },
        store: { method: 'post', name: 'projects.store', page: usePage() },
        edit: { method: 'get', name: 'projects.edit', page: usePage() },
        update: { method: 'patch', name: 'projects.update', page: usePage() },
    };
}
